import { useContext, useEffect } from "react";
import axios from "axios";
import { UserContext } from "../context/userContext";

import { details } from "./details.js";

export default function useFetchEventsData() {
	const { setEventsData } = useContext(UserContext);

	const url = "https://masterapi.springfest.in/api/event";

	useEffect(() => {
		async function fetchData() {
			try {
				// const res = await axios.get(url);
				// let data = await res.data.message;
				
				//hardcoding the data fro new
				let data = details;
				// console.log("the fetched data is ",data)

				const filteredData = data
					.map((genre) => ({
						...genre,
						events: genre.events.filter(
							(subevent) => subevent.event_status === true
						),
					}))

					.filter((genre) => genre.events.length > 0);

				//setting the data in the context
				setEventsData(filteredData);

				// console.log("the filtered data is ",filteredData)
			} catch (err) {
				console.log(err);
			}
		}
		fetchData();
	}, [setEventsData, url]);
}
