import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import registerEvent from "../../utils/handleRegister";
import CloseIcon from "@mui/icons-material/Close";
import createToast from "../../utils/createToast";
import { useParams } from "react-router-dom";

export default function EventRegisterModal({
  openSubEventRegisterModal,
  onCloseSubEventRegisterModal,
  subEvent,
}) {
  const { user, isLoggedIn } = useContext(UserContext);
  const [chosenNumberOfParticipants, setChosenNumberOfParticipants] = useState(
    subEvent.min_participation
  );
  const [memberIDs, setMemberIDs] = useState(
    isLoggedIn ? [user.user.sfId] : []
  );
  const [memberEmail, setMemberEmail] = useState(
    isLoggedIn ? [user.user.email] : []
  );

  const genre = useParams().genre;
  const img = `/assets/Events/${genre}/${subEvent.name.replaceAll(
    " ",
    ""
  )}.png`;

  const numberOfParticipantsArray = [];
  for (let i = subEvent.min_participation; i <= subEvent.max_participation; i++)
    numberOfParticipantsArray.push(i);

  const handleMemberIDChange = (index, value) => {
    const updatedMemberIDs = [...memberIDs];
    if (updatedMemberIDs.length === index) {
      updatedMemberIDs.push(value);
      setMemberIDs(updatedMemberIDs);
      return;
    }
    updatedMemberIDs[index] = value;
    setMemberIDs(updatedMemberIDs);
  };

  const handleMemberEmailChange = (index, value) => {
    const updatedMemberEmail = [...memberEmail];
    if (updatedMemberEmail.length === index) {
      updatedMemberEmail.push(value);
      setMemberEmail(updatedMemberEmail);
      return;
    }
    updatedMemberEmail[index] = value;
    setMemberEmail(updatedMemberEmail);
  };

  const handleRegister = async () => {
    if (!isLoggedIn) {
      createToast("Please login to register for events", "warning");
      return;
    }

    try {
      const registrationData = {};
      registrationData.eventCity = user.user.city;
      registrationData.token = user.token;
      registrationData.eventId = subEvent.id;
      registrationData.teamMembers = memberIDs.map((id, index) => {
        let tempId = {
          sfId: id,
          email: memberEmail[index],
        };
        return tempId;
      });

      const response = await registerEvent(registrationData);
      console.log(response);

      if (response.code === 0) {
        createToast(response.message, "success");
        onCloseSubEventRegisterModal();
      } else {
        createToast(response.message, "warning");
      }
    } catch (error) {
      console.log("error occurred in sending data", error);
    }
  };

  return (
    <div
      className="sub-event-register-modal-overlay"
      onClick={onCloseSubEventRegisterModal}
    >
      <div
        className="sub-event-register-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          className="login-close"
          style={{ color: "white", cursor: "pointer" }}
          onClick={onCloseSubEventRegisterModal}
        />
        <div className="sub-event-register-modal-content">
          <h1 className="sub-event-register-modal-title">
            Register for <br /> "{subEvent.name}"
          </h1>

          <div className="sub-event-register-modal-image">
            <img src={img} alt="sub event " />
          </div>

          {subEvent.is_group === true && (
            <>
              <div className="sub-event-register-modal-note">
                Note: You will be considered as team leader. If anyone other
                than you is supposed to be the leader, ask him/her to register.
              </div>
              <select
                name="Number Of Participants"
                className="choose-number-of-participants"
                onChange={(e) => {
                  setChosenNumberOfParticipants(parseInt(e.target.value));
                }}
              >
                {numberOfParticipantsArray.map((numberOfParticipant, index) => (
                  <option key={index}>{numberOfParticipant}</option>
                ))}
              </select>
            </>
          )}

          <div className="sub-event-register-modal-inputs">
            <div className="sub-event-register-modal-input">
              <div className="sub-event-register-modal-input-label">
                Your User ID
              </div>

              {isLoggedIn ? (
                <>
                  <input
                    type="text"
                    placeholder="Enter your user ID"
                    disabled
                    value={user.user.sfId}
                  />
                  <div className="sub-event-register-modal-input-label">
                    Your EmailID
                  </div>
                  <input
                    type="text"
                    placeholder="Enter your Email"
                    disabled
                    value={user.user.email}
                  />
                </>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="Login to see your id"
                    disabled
                  />
                  <input
                    type="text"
                    placeholder="Login to see Email"
                    disabled
                  />
                </>
              )}
            </div>

            {subEvent.is_group === true &&
              [...Array(chosenNumberOfParticipants)].map(
                (numberOfParticipant, index) =>
                  index !== 0 && (
                    <>
                      <div
                        className="sub-event-register-modal-input"
                        key={index}
                      >
                        <div className="sub-event-register-modal-input-label">
                          Member {index + 1} ID
                        </div>
                        <input
                          type="text"
                          placeholder={`Member ${index + 1} ID`}
                          required
                          onChange={(e) =>
                            handleMemberIDChange(index, e.target.value)
                          }
                        />
                        <div className="sub-event-register-modal-input-label">
                          Member {index + 1} Email
                        </div>
                        <input
                          type="text"
                          placeholder={`Member ${index + 1} Email`}
                          required
                          onChange={(e) =>
                            handleMemberEmailChange(index, e.target.value)
                          }
                        />
                      </div>
                    </>
                  )
              )}
          </div>

          <button
            className="sub-event-modal-register-button"
            onClick={handleRegister}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
}
