import "./App.css";

import LandingPage from "./Pages/LandingPage";
import Events from "./Pages/Events";
import SubEvents from "./Pages/SubEvents";
import Gallery from "./Pages/Gallery";
import Accommodation from "./Pages/Accomodation.jsx";
import NoPage from "./Pages/NoPage";
import Dashboard from "./Pages/Dashboard";
import Navbar from "./components/Navbar";


import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import YoutubeEmbed from "./Pages/YoutubeEmbed.jsx";
import FAQBeta from "./Pages/FAQBeta.jsx";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "./context/userContext.js";
import Game from "./components/Game/Game.tsx"
import MerchResume from "./Pages/MerchResume.jsx";

export default function App() {
    const { setUser, isLoggedIn, hallallocated, setHallallocated } = useContext(UserContext);

    ReactGA.initialize("G-F88C3CQ20H");
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home2D" });


    return (
        <>
            <Routes>
                <Route index path="/" element={<LandingPage />} />
                <Route path="dashboard" element={<Dashboard />} 
                // onEnter={fetchUserData}
                />
                {/* <Route path="merch/*" element={<Merch />} /> */}
                <Route path="events" element={<Events />} />
                <Route path="events/:genre" element={<SubEvents />} />
                <Route path="accommodation/*" element={<Accommodation />} />
                <Route path="gallery" element={<Gallery />} />
                <Route
                    path="movie"
                    element={<YoutubeEmbed embedId={"r-Tr-ivYPt0"} />}
                />
                <Route path="/tilesladder" element={<Game />} />
                <Route path="/faq" element={<FAQBeta />} />
                {/* <Route path="merch/my-cart" element={<Cart />} /> */}
                <Route path="merch" element={<MerchResume />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
            <Navbar />
            <ToastContainer />
        </>
    );
}
