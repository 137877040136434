import "../styles/NoPage.css";

export default function MerchResume() {
    return (
        <div className="no-page-container">
            <div className="no-page-table">
                <div className="no-page-monitor-wrapper">
                    <div className="no-page-monitor">
                        <p>Coming Soon</p>
                    </div>
                </div>
            </div>
        </div>
    );
}