import React, { useState } from "react";
import LoginPage from "../Pages/LoginPage";
import { Link } from "react-router-dom";
import "../styles/LandingPage.css";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import axios from "axios";

import SFLogo from "../temp-imgs/sf-logo.webp";
import IITKGPLogo from "../temp-imgs/iit-kgp-logo.webp";
import IILU_NEW from "../temp-imgs/SF_logo_white.png";

const LandingPage = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const { user, setUser, isLoggedIn, setHallallocated } =
		useContext(UserContext);

	const openModal = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	// const fetchUserData = async () => {
	// 	console.log("fetchUserData function started from dashboard");
	// 	if (!isLoggedIn) return;
	// 	try {
	// 		const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};

	// 		const res = await axios.post(
	// 			"https://acco.springfest.in/api/user/getUser",
	// 			{
	// 				token: storedUserData.token,
	// 			}
	// 		);

	// 		if (res.data.code === 0) {
	// 			setHallallocated(res.data.hallAlloted);
	// 			const updatedUser = res.data.data;

	// 			storedUserData.payment_status = updatedUser.payment_status;
	// 			// storedUserData.payment_status = 1;

	// 			localStorage.setItem("userData", JSON.stringify(storedUserData));
	// 			setUser(storedUserData);
	// 		} else {
	// 			console.log("error");
	// 		}
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	return (
		<div className="landing-page">
			<div className="navbar-logo">
				<a href="https://www.springfest.in">
					<img src={SFLogo} alt="SF_Logo" className="SF_Logo" />
				</a>
				<a href="https://www.iitkgp.ac.in">
					<img src={IITKGPLogo} alt="IIT_KGP_LOGO" className="IIT_KGP_LOGO" />
				</a>
			</div>

			{!isModalOpen && (
				<div className="">
					<div className="landing-page-ilu">
						<img src={IILU_NEW} alt="ilu" className="landing-page-ilu-img" />
						{isLoggedIn ? (
							<div className="dashboard-lower-links">
								<Link
									to="dashboard"
									className="landing-page-dashboard-button"
									// onClick={fetchUserData}
								>
									Dashboard
								</Link>
								{/* {user && !user.payment_status && (
									<Link
										to="/dashboard/payment"
										className="dashboard-pay-button"
									>
										Pay Now
									</Link>
								)} */}
							</div>
						) : (
							<button className="landing-page-login-button" onClick={openModal}>
								Log In
							</button>
						)}
					</div>
				</div>
			)}
			<LoginPage isOpen={isModalOpen} onClose={closeModal} />
		</div>
	);
};

export default LandingPage;
