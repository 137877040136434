import { Routes, Route, useNavigate } from "react-router-dom";
import "../styles/Accomodation.scss";
import AccoSideNav from "../components/Accomodation/AccoSideNav";
import AccomodationContent from "../components/Accomodation/AccoContent";
import { UserContext } from "../context/userContext.js";
import { useContext } from "react";


export default function Accomodation() {

    const navigate = useNavigate();
    const { isLoggedIn } =  useContext(UserContext);

    return (
        <div className="accomodation-container">
            <AccoSideNav />
            <Routes>
                <Route path="/" element={<AccomodationContent mainheading={"INFO"} />} />
                <Route path="/maptokgp" element={<AccomodationContent mainheading={"REACHING IIT KGP"} />} />
                <Route path="/faq" element={<AccomodationContent mainheading={"FAQS"} />} />
                <Route path="/rules" element={<AccomodationContent mainheading={"RULES"} />} />
            </Routes>

        </div>
    );
}