export const details = [
    {
        "genre": "Dance",
        "events": [
            {
                "id": 1,
                "genre": "Dance",
                "name": "Centrifuge",
                "tagline": "Group Dance Competition",
                "writeup": "The inter-collegiate group dance competition is a platform for the best dancing troops across the country to flaunt their hypnotic moves. Step onto our stage where synchronized moves and collective energy create a mesmerizing spectacle.",
                "min_participation": 4,
                "max_participation": 15,
                "rules": [
                    "",
                    "1. The time limit for the overall performance is 5 minutes exceeding which by 1 minute will lead to disqualification.",
                    "2. Participants may use one or more songs in the performance within 5 minutes.",
                    "3. The maximum team size is of 15 members",
                    "4. Participants should get their songs/music in a pen-drive. Live music is not allowed.",
                    "5. Use of props is allowed.",
                    "6. Teams are STRICTLY prohibited from mentioning college names during the performance by any medium. Any team doing so will be awarded a penalty.",
                    "7. All dance forms including hip-hop, Punjabi, Jazz, Kathakali, Contemporary etc are allowed.",
                    "8. Judging criteria includes:\n\n A. Synchronisation and coordination (40 points)\n\n B. Choreography and creativity (30 points)\n\n C. Artistic Presentation (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "9. Winners get a chance to grab a direct spot in Centrifuge during the main\nfest."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 2,
                "genre": "Dance",
                "name": "Nrityakala",
                "tagline": "Solo Classical Dance Competition",
                "writeup": "Embrace the beauty of tradition as you take the stage. Join our solo classical dance competition, nrityakala to showcase your finesse, portraying stories through the mesmerizing language of classical dance.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Participants should get their songs/music in a pen-drive. Live music is not allowed.",
                    "2. Time limit is 3 minutes (music on to music off) exceeding which by 1 minute will lead to disqualification.",
                    "3. All forms of classical dance are allowed.",
                    "4. Accessories, costumes and props will have to be arranged by the participants.",
                    "5. Judging criteria includes:\n\n A. Technique and Execution (40 points)\n\n B. Emotion and Expression (30 points)\n\n C. Artistic Interpretation (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "6. Winners get a chance to grab a direct spot in Nrityakala during the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 3,
                "genre": "Dance",
                "name": "Shake A Leg",
                "tagline": "Solo Western Dance Competition",
                "writeup": "Step into the spotlight alone and let your dance tell a unique story. Showcase your skill and style in our solo dance competition, where your individual artistry takes center stage, captivating hearts with every graceful movement.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Participants should get their songs/music in a pen-drive. Live music is not allowed.",
                    "2. Time limit is 3 minutes (music on to music off) exceeding which by 1 minute will lead to disqualification.",
                    "3. In case of malfunctioning of any equipment provided, the participant will be allowed to repeat the performance with the consent of the judges.",
                    "4. Accessories, costumes and props have to be arranged by the participants.",
                    "5. Judging criteria includes:\n\n A. choreography (35 points)\n\n B. Emotion and Attitude (40 points)\n\n C. Stage utilisation and crowd response (25 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition",
                    "6. Winners get a chance to grab a direct spot in Shake a leg for the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 5,
                "genre": "Dance",
                "name": "Two For A Tango",
                "tagline": "Duet Dance competition",
                "writeup": "Pair up and dance in harmony! Join our duet dance competition, where your\nsynchronized moves and chemistry with your partner create an enchanting\nperformance that steals the spotlight.",
                "min_participation": 2,
                "max_participation": 2,
                "rules": [
                    "",
                    "1. The time limit for the overall performance is 3 minutes exceeding which by 1 minute will lead to disqualification.",
                    "2. Participants may use one or more songs in their performance within the time limit.",
                    "3. The event is strictly a duo dance competition and there shouldn't be any extra person on stage at any point during the performance.",
                    "4. Any dance form is allowed.",
                    "5. Accessories, costumes and props will have to be arranged by the participants.",
                    "6. Participants should get their songs/music in a pen-drive. Live music is not allowed.",
                    "7. Judging criteria includes:\n\n A. Synchronisation and partnering (40 points)\n\n B. Emotional expression and chemistry (30 points)\n\n C. Choreography and artistry (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition",
                    "8. Winners get a chance to grab a direct spot in Two for a Tango during the main fest."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 4,
                "genre": "Dance",
                "name": "Shuffle",
                "tagline": "The Nationwide Street Dance Competition",
                "writeup": "The nationwide street dance competition. Have you got what it takes to sweep the crowd off\nits feet? Your style be hip-hop, tutting, crumping, breakdancing, stepping, b-boying,\ncontraption or western freestyle dancing, this is your chance to take over the street stage.\nTeam size — min 4 and max 10. Props are not allowed.",
                "min_participation": 4,
                "max_participation": 10,
                "rules": [
                    "Prelims",
                    "1. The time limit is 4 min to 6 min (length of the music track).",
                    "2. Participants should perform for more than 2 tracks.\n\nPre-finals",
                    "1. The time limit is 2min+2min (music on to music off).",
                    "2. 2 hours after the completion of the preliminary round at IIT Kharagpur, each team would be provided with 2 music\ntracks (1 +1 minutes) for preparation. Different teams would be provided with different music tracks.",
                    "3. In the time provided, the teams are to choreograph and prepare a dance to the 2 music tracks provided to them\nalong with a 2-minute song chosen by the team.",
                    "4. The team needs to bring a continuous soundtrack of 4 minutes including 2 minutes of their own song and the 2\nminutes of music provided by Spring Fest and submit it to the organizers 1 hour before the start of the event\n(failing this would lead to disqualification).",
                    "5. The teams cannot repeat the performance of the prelims.\n\nFinals",
                    "1. The teams ranked in the top 4 in the pre-finals will compete in the finals. Teams\nqualifying for this round will be going against each other in a face-off competition.\n\nThe order of the teams will be decided through a draw system and the battles\nwould be as follows:\n\na. Battle 1: Team 1 vs. Team 2\n\nb. Battle 2: Team 3 vs. Team 4\n\nc. Final Battle: Winner Battle 1 vs. Winner Battle 2\n\nd. 3rd Position Battle: Runner-up Battle 1 vs. Runner-up Battle 2",
                    "2. The music tracks will be provided by the Spring Fest Team on the venue itself.",
                    "3. SAME MUSIC WILL BE PROVIDED TO BOTH THE TEAM.",
                    "4. Each Battle will be in FIVE rounds. In each round, maximum 4 members from\neach team can go one after the other.",
                    "5. The time limit for each round is 1 min per team (music on to music off) i.e. 1\nmin x 2 teams x 5 rounds = Total of 10 minutes for each battle.",
                    "6. In case of a tie an extra round may be provided.",
                    "7. Judging criteria: · Choreography · Stage Utilization · Costumes · Energy Level ·\nInnovation · Overall impact on the crowd · \nFor the FACE-OFF round, crowd support\nand most of all, overshadowing the opposite team will be important factors.",
                    "8.The performance should be in cohesion with the integrity of the fest. The songs selected by the\nparticipants must not contain any foul language. Violation of any of the above will lead to immediate\ndisqualification.\n\nThe decision of the judges will be final and binding. The organizing committee reserves the\nright to make any last minute change in the rules."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Music",
        "events": [
            {
                "id": 6,
                "genre": "Music",
                "name": "Beat IT",
                "tagline": "Beatboxing Competition",
                "writeup": "Calling all aspiring beatboxers! Showcase your rhythmic prowess and original vocal beats in our exciting beatboxing competition and be recognized for your unique talent and creativity",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. It is a solo competition.",
                    "2. Time limit for the performance will be 2 minutes, exceeding which by 20s\nwill lead to disqualification.",
                    "3. Use of musical instruments will lead to disqualification.",
                    "4. Judging criteria includes:\n\n A. Technical skills (40 points)\n\n B. Creativity and originality (30 points)\n\n C. Performance and stage presence (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "5. Winners get a chance to grab a direct spot in BEAT IT finale in the main fest"
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 9,
                "genre": "Music",
                "name": "Rapmania",
                "tagline": "Solo Rapping Competition",
                "writeup": "Ready to spit fire and own the mic? Step up to our rapping competition,\nwhere your lyrical flow and authentic style take center stage, showcasing\nyour passion and paving the way for rap supremacy!",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Time limit for the performance will be 3 minutes, exceeding which by 20s\nwill lead to disqualification.",
                    "2. Participants can use a backing track if required.",
                    "3. Any sort of racist, sexist or offensive slurs will lead to immediate disqualification.",
                    "4. The language of rap can be English and/or Hindi.",
                    "5. Judging criteria includes:\n\n A. Lyricism and content (40 points)\n\n B. Delivery and flow (30 points)\n\n C. Stage presence and audience engagement (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "6. Winners get a chance to grab a direct spot in RAPMANIA finale during the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 12,
                "genre": "Music",
                "name": "SF Idol",
                "tagline": "Solo Singing",
                "writeup": "Get ready to unveil the singer within you as Spring Fest presents SF Idol, the\nsolo singing competition which celebrates the raw talent and boundless\ncreativity of solo singers, leaving a lasting impact on both the stage and the\nhearts of the audience.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Participants can perform any number of songs (in Hindi, English or both) within a time limit of 3 minutes.",
                    "2. Exceeding the time limit by 20 seconds will lead to disqualification.",
                    "3. Participants can optionally perform with one instrumental accompaniment or with a backing track..",
                    "4. Any misconduct, obscenity or foul language can lead to on the spontaneous disqualification.",
                    "5. Judging criteria includes:\n\n A. Vocal Excellence (40 points)\n\n B. Performance and Expression (30 points)\n\n C. Overall Presentation (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "6. Winners get a chance to grab a direct spot in SF Idol finale in the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 8,
                "genre": "Music",
                "name": "Notes Less Travelled",
                "tagline": "Solo Instrumental Competition",
                "writeup": "If you can express yourself with music more than words, If plink of a guitar, thaap of a tabla , or ethereal hum of a flute harmonizes with the beat of your heart, Then grab the opportunity to take the audience to your musical world!. This is an individual event and will take place in 2 rounds : Online Prelims, Finals\n",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "1) Judging Criteria: Accuracy of notes, Rhythm, selection of composition/original composition, Technique, Impact.\n\n 2) Time limit is 3-4 minutes.\n\n 3) This is an individual event.\n\n 4) The instrument for playing in the event has to be carried by the participant.\n\n 5) Back tracks are allowed but they have to be carried in a Pendrive or a CD to the venue."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 11,
                "genre": "Music",
                "name": "Sargam",
                "tagline": "Eastern Fusion Band Competition",
                "writeup": "This spring get ready to float with the melodies of indian classical and folk music with a twist\nof fusion. The eastern fusion group competition witnesses huge participation and gives a\nplatform to various maestros in the rising. Tune your instruments and strike the right notes to\nbeat some of the ustads of indian music and win prizes worth more than a lakh rupees.\nThe event is open to any eastern group and songs in hindi are permitted. The event will take\nplacein 1 round. There is no limit on the team size.",
                "min_participation": 3,
                "max_participation": 30,
                "rules": [
                    "",
                    "1. A group can perform for a maximum of 20 minutes (Amps on to amps off\ninclusive of sound check) and has to perform at least 3 numbers within the given\ntime.",
                    "2. Only keyboard part of synthesizer is allowed (Rhythm part is not allowed).",
                    "3. Groups will have to bring their own instruments and special effects. However,\nprogrammed music is not allowed.",
                    "4. Equipment provided will be: a. One bass amplifier speaker. b. One lead amplifier\nspeaker. c. Adequate microphones. d. 10000 Watts P.A. System",
                    "5. Drum set (you can bring along with your own cymbals and chokes/high hats).",
                    "6. Spring Fest team will not be held responsible for the failure of any instruments.",
                    "7. Tuning facilities would be provided backstage. The decision of the judges will be\nfinal and binding."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 10,
                "genre": "Music",
                "name": "Retrowave",
                "tagline": "Solo Remix Competition",
                "writeup": "With the best music-making community in rise and the level of production skillz\ncoming through the country; start working your magic!. This is an individual event and will take place in 1 round : online finals judging criteria: originality, quality of production, use of samples, use of tracks, mixing/mastering",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "1) Participants need to submit their Digital Audio Workstation(DAW) file of the\nsoftware used in music production. (For Example: If you are using FL Studio; send\nthe .flp file)\n\n 2) The participants need to submit the judging entries(tracks prepared) in any\nuncompressed audio format. (The audio file sent for judging shall be the same as\nthe DAW file submitted.) Time Limit: 3 - 4 minutes.\n\n 3) The original tracks selected shall not be remixes. Copying and stealing the\nremixes will lead to disqualification. If found using pre-looped tracks/ pre-extended\nmixes/ re- cued tracks/ pre-pitched tracks will be disqualified.\n\n 4) This is an individual event\n\n 5) The entry should be submitted to music.sf2023@gmail.com."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 7,
                "genre": "Music",
                "name": "Lakeside Dreams",
                "tagline": "Acoustic Jamming",
                "writeup": "Have you been into jamming recently? Be it solo or in groups, lake side dreams is the perfect platform to showcase your fervour in acoustic jamming. The competition invites some of the best musicians from across india to sing to the tunes of nature at the lake side stage. This competition is open to any acoustic jam based band and individual performer (without an accompanist) and will take place in 1 round. The winners will be separate for individuals and groups. Vocals are compulsory. The judging will be on the overall performance and not just on the instrumental performance.",
                "min_participation": 1,
                "max_participation": 15,
                "rules": [
                    "",
                    "1. Only songs in English and/or Hindi are permissible.",
                    "2. Time limit for individual performance is a minimum of 2 and a maximum of 7 minutes and for a group performance is a maximum of 15 minutes, inclusive of sound check.",
                    "3. Only non-electrical musical instruments (guitars, flutes etc.) are allowed. However one synthesizer is allowed per entry. A bass guitar can be used without a processor.",
                    "4. Patch change for synthesizers is not allowed in the middle of a song.",
                    "5. The following equipment will be provided by us:\n\n a.Power supply for synthesizer\n\n b.Microphones and sounds",
                    "6.Decision of the judges will be final and binding.(Can decrease 1 minute)"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 13,
                "genre": "Music",
                "name": "Wildfire",
                "tagline": "Nationwide Western Rock Band Competition",
                "writeup": "Wildfire is India’s oldest and most prestigious rock band competition. With prizes worth\nlakhs of rupees and your prestige at stake, wildfire has been the launch pad for many\ncontemporary bands including - underground authority, parikrama, Kryptos, Zygnema,\nCassini’s division, and many more. So be ready to fulfill your love for rock at the wildfire\nfinals at Kharagpur! Wildfire is a western and eastern rock band competition, open to any\ncollege or semi-professional band from India and abroad.",
                "min_participation": 3,
                "max_participation": 12,
                "rules": [
                    "",
                    "1. Offline prelims will be conducted in Bangalore, Delhi, Mumbai, Kolkata, Shillong\nand Jaipur.",
                    "2. After the first round, a shortlist of bands would be released who will qualify to\nperform at the main event at IIT Kharagpur.",
                    "3. The main event at IIT Kharagpur would consist of 2 rounds: prefinals and finals.",
                    "4. In prefinals at IIT Kharagpur, a group will be required to perform at least three\nnumbers of their choice within 20 minutes. (Amps on to amps off and inclusive of\nsound check)",
                    "5. In finals, a group will be required to perform at least 4 numbers of their choice\nwithin 40 minutes (Amps on to amps off inclusive of sound check).",
                    "6. Bands will be disqualified on the spot for misconduct, obscenity or foul\nlanguage, and will be banned from performing at all subsequent editions of spring\nfest.",
                    "7. Only English and Hindi Rock Music are allowed",
                    "8. Equipment provided will be: a. One bass amplifier speaker b. One lead amplifier\nspeaker c. Adequate microphones d. Drum set (Participants can bring along with\nyour own cymbals and chokes/high hats).",
                    "9. Bands will have to bring their own musical instruments and special effects.\nHowever, programmed music is not allowed.",
                    "10. Spring Fest will not be held responsible for the failure of any instrument."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Dramatics",
        "events": [
            {
                "id": 14,
                "genre": "Dramatics",
                "name": "I Me Myself",
                "tagline": "Mono-Acting Competition",
                "writeup": "Step into the spotlight and embody characters that resonate. Elevate your solo performance at our mono-acting competition, where your portrayal's depth and emotions take center stage.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event where participants have to present a mono act on any theme.",
                    "2. 1 person is allowed to assist with sounds and 2 people for the lights at max.",
                    "3. Language of the act can be English or Hindi.",
                    "4. Total time allotted for every performance is 4 minutes (empty stage to empty stage)",
                    "5. Exceeding the time limit by 1 minute will lead to disqualification.",
                    "6. Participants can bring their own music for the act in a pen drive but recorded vocals are not allowed.",
                    "7. No naked flames or live animals are allowed on stage.",
                    "8. Any instance of vulgarity or disturbing content of any order will lead to forceful halt of performance and immediate disqualification.",
                    "9. Judging criteria includes:\n\n A. Character potrayal (40 points)\n\n B. Delivery and expression (30 points)\n\n C. Interpretation and creativity (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "10. Winners get a chance to grab a direct spot in I Me Myself in the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 16,
                "genre": "Dramatics",
                "name": "Nukkad",
                "tagline": "Street Play",
                "writeup": "Exploiting the power of a street gathering, nukkad or street plays have been\nthe medium of the voice of the people from time immemorial. Unleash your\ncreativity on the pavement stage! Join our street play competition, where\nyour voice becomes a powerful instrument for social change, blending art\nand activism in the most impactful way.",
                "min_participation": 6,
                "max_participation": 20,
                "rules": [
                    "",
                    "1. Teams will perform at an open-air venue with audience on all sides.",
                    "2. The permitted team limit is 6-20 participants excluding 2 instrumentalists.(optional)",
                    "3. Time limit shall be 15 minutes, exceeding which by 3 minutes will lead to disqualification.",
                    "4. The time duration includes performance time and stage setting time. The given time duration is from empty stage to empty stage.",
                    "5. Usage of fire, water or smoke is not allowed. Any props, if used, must be cleared off by the participants after the performance within the time limit.",
                    "6. Any instance of vulgarity or disturbing content of any order will lead to forceful halt of performance and immediate disqualification.",
                    "7.Only live music is allowed. Teams will have to bring their own instruments.",
                    "8. Judging criteria includes:\n\n A. Content, theme and message (40 points)\n\n B. Performance (25 points)\n\n C. Interaction and improvisation (20 points)\n\n D. Direction, gimmicks and effects (15 points)\n\n The judges' collective scores across these categories will determine the final\noutcome of the competition.",
                    "9. Winners get a chance to grab a direct spot in Nukkad during the main fest."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 15,
                "genre": "Dramatics",
                "name": "Mime",
                "tagline": "Silent Act",
                "writeup": "Sometimes silence says what words can't. Spring fest presents you an opportunity to\nshowcase your talent of expressions, gesticulations and body movements without use of\nspeech so bring out your excellent dramatic and creative side and amaze the audience.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Performance should be a non-verbal act.",
                    "2. Time limit is 4-5 minutes.",
                    "3. This is an individual event.",
                    "4. Instrumental Music can be used in the background. Music shouldn’t be vocal.",
                    "5. Video Editing is not allowed."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 17,
                "genre": "Dramatics",
                "name": "Rangmanch",
                "tagline": "Stage Play",
                "writeup": "If you think you can mesmerize the audience with acting, script, dialogues and sets - then Rangmanch is\nyour call! It cannot get better than the annual stage play competition at Spring Fest. Once the stage is set\nand all is ready - it is show time!",
                "min_participation": 5,
                "max_participation": 25,
                "rules": [
                    "",
                    "1. This is a group event. Each team should have a minimum of 5 actors. In\naddition, there can be one person in charge of music and a maximum of 3 people\nfor lights.",
                    "2. The event will take place in 2 rounds, prelims and finals. Total time allotted for\neach team in the prelims is 15 minutes and for the finals is 50 minutes.",
                    "3. Language of the play can be English/Hindi. Mime is allowed.",
                    "4. Stage Area: 20ft x 36ft (approx.)",
                    "5. Pre-registration before 15th January is mandatory. Teams should mail a soft\ncopy of the script to dramatics.sf2023@gmail.com.",
                    "6. This is an inter-collegiate event and there shall be only 1 team per college. The\nteams should carry an official letter from their respective institutes signed by a\ncompetent authority, mentioning the names of the participants.",
                    "7. Once a team registers, their preference for a slot for prelims and lights practice\nwill be taken by email. The organizing team shall try to give the preferential slot as\nlong as it is available. The decision made by the organizing team will be final and\nbinding.",
                    "8. The teams will be informed regarding their lights practice and prelims slots by\nemail.",
                    "9. The time duration for prelims and finals includes performance time and stage\nsetting time. The given time duration is from the empty stage to the empty stage.",
                    "10. In the finals, if a team exceeds 50 minutes, 15% of the total works obtained\nwill be deducted. If a team exceeds 55 min, it stands to be debarred from the\nevent.",
                    "11. Judging Criterion:\n\n a. Content - 40%\n\n b. Acting/Expression- 30%\n\n c. Direction - 20%\n\n d. Miscellaneous Effects - 10%",
                    "12. Microphones and backstage mikes for narration, lights, and a laptop to play\nsounds will be provided.",
                    "13. Recorded vocals are not allowed, only instrumental music is allowed.",
                    "14. The teams are expected to bring their own music on CD or pen drive for the\nplay.",
                    "15. In case of malfunctioning of any equipment provided, the team will be allowed\nto repeat their performance from the last scene change or from the point of\ndisruption that the team wishes. It depends on the judge’s discretion as to how\nmuch grace time will be given to the performing team.",
                    "16. All costumes and accessories are to be arranged by the participating team. The\norganizing committee is responsible for the infrastructural facilities only and shall\nnot be responsible for the security of items left behind in green rooms or on stage\nafter the performance.",
                    "17. Requests for special effects and props should be communicated at least a\nweek in advance by both emails and over the telephone. The same will be provided\nonly if possible.",
                    "18. No naked flames or live animals are allowed on stage.",
                    "19. The participating teams are requested to ensure that their presentation is in\nkeeping with the dignity of the fest."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Literary",
        "events": [
            {
                "id": 27,
                "genre": "Literary",
                "name": "Poetry Slam",
                "tagline": "Poetry Recital Competition",
                "writeup": "Unleash your words and emotions on our poetic stage. Join the poetry competition to weave your verses into a tapestry of expression, where each syllable carries the power to captivate and resonate.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Poems can be on any subject and in any style but must be original and in Hindi or English only.",
                    "2. Use of props, special costumes, musical instruments or pre-recorded music is not allowed.",
                    "3. Poems once performed may not be repeated in further rounds, except in case of tie-breaking rounds, violation of which disqualifies the poet.",
                    "4. Time limit is three minutes, exceeding which by 30 seconds will lead to disqualification.",
                    "5. Participant may not repeat primary authorship in a bout. Every poet who performs during a bout must be a primary author.",
                    "6. Judging criteria includes:\n\n A. Content and theme (40 points)\n\n B. Language and delivery (30 points)\n\n C. Emotion and connection (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "7. Winners get a chance to grab a direct spot in Poetry slam (Hindi and english respectively) during the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 18,
                "genre": "Literary",
                "name": "A Mighty Pen",
                "tagline": "Journalism Based Event",
                "writeup": "This is the journalism-based event of spring fest where the participants strive to bring about at least a small change in the society around us with the power of their pens. The problem statement would be released one month before the conduction of the final event.",
                "min_participation": 1,
                "max_participation": 3,
                "rules": [
                    "",
                    "1. This is a group-based event. The number of members allowed per team is",
                    "3.",
                    "2. The teams will be given a journalism-based problem statement one month before the fest.",
                    "3. They have to prepare a report of up to 1500 words on the given topic, containing adequate pictures and data to support it.",
                    "4. A soft copy of the report should be submitted before 20th January 2019 to literary.sf2023@gmail.com and a hard copy at the time of presentation.",
                    "5. The teams are free to decide their way of presentation of the report.",
                    "6. The team will have 20 minutes to present the report.",
                    "7. The marks distribution will be as follows:\n\n a. Report Content - 30%\n\n b. Visual Content (graphs and Data Tables) - 20% \n\n c. Interactivity with the reader - 25% \n\n d. Presentation - 25%"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 20,
                "genre": "Literary",
                "name": "English Poetry Slam",
                "tagline": "English Poetry Recital",
                "writeup": "One of the events of the fest, which involves the combined art of story-telling, stand-up and\npoetry writing, poetry slam happens to be the flagship event of literary genre. The event\nattracts poetry enthusiasts from all over the country to enchant the audience and fill their\nartistic hunger. The event serves instrumental in recognizing the new generation poets in\nIndia.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Poems can be on any subject and in any style but must be original and in Hindi\nor English only.",
                    "2. Use of props, special costumes, musical instruments or pre-recorded music is\nnot allowed.",
                    "3. There is no upper limit of participants from a particular college. The judging shall\nhowever be done individually.",
                    "4. Poems once performed may not be repeated in further rounds, except in case of\ntie-breaking rounds, violation of which disqualifies the poet. Therefore, each poet\nshould plan on bringing at least 3 original poems to compete, with an extra poem\nas buffer for a tie.",
                    "5. Time limit is three minutes. After three minutes, there is a 10-second grace\nperiod after which a penalty of",
                    "0.5 is automatically deducted, without warning,\nfrom each poet's overall score for every subsequent 10 seconds.",
                    "6. Teams may not repeat primary authorship in about. Every poet who performs\nduring a bout must be a primary author.",
                    "7. The judges’ will give each poem a score from 0 to",
                    "10.",
                    "8. The sum of all the scores by all the judges in the panel for a particular round and\nthe grand total of each bout will be considered for their qualification to next bout.",
                    "9. The best 3 poets shall be adjudged the winners of Poetry Slam."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 19,
                "genre": "Literary",
                "name": "Dumb C",
                "tagline": "Dumb Charades",
                "writeup": "This vintage game brings out all the memories of the endless games of dumb charades we played since when we were kids. So, get ready to live all those memories again, as we take you to a whole new level of competitiveness in this much-loved game!",
                "min_participation": 3,
                "max_participation": 3,
                "rules": [
                    "",
                    "1. This is a team event with team size",
                    "3.",
                    "2. The event will take place in two rounds, prelims and finals.",
                    "3. In the prelims, there will be 25 questions on Movies /Literature/Personality/Advertisements flashed on the screen.",
                    "4. The top 8 teams will qualify for the finals which will be multiple rounds of Dumb Charades.",
                    "5. In the finals, in each round, one person from the team will act out the clue and the others will guess.",
                    "6. The 1st round will be based on Movies.",
                    "7. 2nd round will be based on Movie Personalities.",
                    "8. A maximum of 45 seconds will be given to the teams to guess the correct movie name and personality.",
                    "9. 3rd round will be based on Dialogues/Songs.",
                    "10. For the 3rd round, enacting the film itself instead of the Dialogues/Songs not allowed.",
                    "11. A maximum of 60 seconds will be given to the teams to guess the correct Dialogues/Songs.",
                    "12. In each of the 3 rounds, every team shall be given 3 clues to enact and each person should be an enactor at least once in all rounds combined.",
                    "13. There will be total 4 guesses to be allowed per clue for each round.",
                    "14. If the team fails to guess the correct answer in the stipulated time, 10 seconds will be added to the team's time in addition to the allocated time for that clue.",
                    "15. The team with the minimum time at the end of three rounds wins."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 21,
                "genre": "Literary",
                "name": "Hindi Poetry Slam",
                "tagline": "Hindi Poetry Recital",
                "writeup": "One of the events of the fest, which involves the combined art of story-telling, stand-up and\npoetry writing, poetry slam happens to be the flagship event of literary genre. The event\nattracts poetry enthusiasts from all over the country to enchant the audience and fill their\nartistic hunger. The event serves instrumental in recognizing the new generation poets in\nIndia.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. Poems can be on any subject and in any style but must be original and in Hindi\nor English only.",
                    "2. Use of props, special costumes, musical instruments or pre-recorded music is\nnot allowed.",
                    "3. There is no upper limit of participants from a particular college. The judging shall\nhowever be done individually.",
                    "4. Poems once performed may not be repeated in further rounds, except in case of\ntie-breaking rounds, violation of which disqualifies the poet. Therefore, each poet\nshould plan on bringing at least 3 original poems to compete, with an extra poem\nas buffer for a tie.",
                    "5. Time limit is three minutes. After three minutes, there is a 10-second grace\nperiod after which a penalty of",
                    "0.5 is automatically deducted, without warning,\nfrom each poet's overall score for every subsequent 10 seconds.",
                    "6. Teams may not repeat primary authorship in about. Every poet who performs\nduring a bout must be a primary author.",
                    "7. The judges’ will give each poem a score from 0 to",
                    "10.",
                    "8. The sum of all the scores by all the judges in the panel for a particular round and\nthe grand total of each bout will be considered for their qualification to next bout.",
                    "9. The best 3 poets shall be adjudged the winners of Poetry Slam."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 23,
                "genre": "Literary",
                "name": "India Calling",
                "tagline": "Case Study",
                "writeup": "Spring Fest presents India Calling, a case study presentation based on a social problem statement. An\nevent for the students and faculty alike as young minds present their ideas that have both financial and\nsocial goals integrated to its purpose! Participants need to be socially motivated and practical into their\napproach. Problem statement for the event will be released\none month before the final event.",
                "min_participation": 2,
                "max_participation": 5,
                "rules": [
                    "",
                    "1. It is a team event, with a maximum of 4 members per team.",
                    "2. For the prelims, all participating teams need to send an abstract of their presentation to literary.sf2023@gmail.com. The presentation should be in pdf format.",
                    "3. The presentation should have a maximum of 30 pictures accompanied by captions and slogans, as well as a 500-word policy proposal.",
                    "4. Mark distribution (Prelims)\n\n a. Pictures, Slogans & Captions(Content) - 40% \n\n b. Pictures, Slogans & Captions (Projection of topic) - 20% \n\n c. Presentation - 20% \n\n d. Policy proposal - 20%",
                    "5. Finalists will be selected on the basis of prelims and shall be notified within 5 days of end of the submission.",
                    "6. For the finals, the teams need to give a 20-minute presentation.",
                    "7. The participants are free to decide the way of presentation of their report.",
                    "8. A hard copy of the report must be submitted to the organizing team at the time of the presentation."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 22,
                "genre": "Literary",
                "name": "Impromptu",
                "tagline": "Impromptu Public Speaking And Elocution",
                "writeup": "This is the extempore speaking competition of Spring Fest that brings out a whole new dimension in the art of public speaking.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. In the finals, the order of finalists will be decided by the draw of lots.",
                    "2. Each participant will be given a social (Picture or Video) topic. He has to speak\non either for or against the topic.",
                    "3. Each participant will be given 30 seconds to think and 90 seconds to speak.",
                    "4. Whenever the judge blows a whistle/ring a bell/give any form of sign, the speaker will have to contradict his/her views. The sign may be given more than once for a contestant or may not be given at all.",
                    "5. If the participant wishes to change the topic he may do so only one time after a penalty of 10% of the total marks.",
                    "6. If the participants don't speak for one minute, they will be disqualified.",
                    "7. For 15 seconds overshoot, a 15 % penalty on marks obtained will be imposed on the speaker.",
                    "8. Exceeding the time limit for more than 30 seconds will lead to disqualification."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 24,
                "genre": "Literary",
                "name": "Jumble The Good Word",
                "tagline": "Word Game",
                "writeup": "A word game where your knowledge, memory, wit and teamwork skills are tested with you having\nimmense fun all along.",
                "min_participation": 2,
                "max_participation": 3,
                "rules": [
                    "",
                    "1. This is a team event with three participants per team.",
                    "2. The event will be conducted in two rounds, prelims and finals.",
                    "3. The prelims will consist of 30 questions to be answered in a 15 minutes duration.",
                    "4. The quiz (final round) will contain various word games like crossword, analogies, scramble the world.",
                    "5. Any communication with the judge will result in disqualification."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 25,
                "genre": "Literary",
                "name": "Just A Minute",
                "tagline": "JAM",
                "writeup": "An event for the grammar nazis and those glib of tongue, where you have to speak fluent and grammatically correct english for one minute without any hesitation, repetition or deviation.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event.",
                    "2. Participants come on stage in random groups of 8 and will play multiple rounds of Just a Minute.",
                    "3. In each round, the participants will be given a topic on which one of the 8 participants on stage will have to start speaking on the spot.",
                    "4. He continues speaking until someone else from the group challenges him by 'jamming' him.",
                    "5. If the challenge is successful, the person who jams, starts speaking in turn. This process continues till 1 minute is over.",
                    "6. At the end of a minute, the next round will start with another topic.",
                    "7. At the end of all the rounds, the participants with the maximum points in the group will move on to the next level.",
                    "8. Points will be allotted as follows: 1 point for every second the participant speaks, in case of a challenge made by any participant on the grounds of hesitation, repetition or deviation, 5 points will be added to his score if he is correct and 5 points will be deducted if he is incorrect. In case of any other challenge as determined by the JAM master, 2 points will be added or subtracted, 5 points will be added to the score of the participant who is speaking at the end of the minute."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 26,
                "genre": "Literary",
                "name": "National Level Debate",
                "tagline": "Debate Competition",
                "writeup": "The National Level Debate of Spring Fest is an oxford union style debating contest in which the best minds from all over India descend to IIT Kharagpur for a battle of wits where only those with the wittiest tongue wins.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. The event will take place in 2 rounds, prelims and finals.",
                    "2. Both rounds will be presidential-style debates.",
                    "3. Top 12 participants based on their points will go through to the final round.",
                    "4. The total time allotted per debate is",
                    "10.5 minutes. The time division is as follows:\n\n a. Speaker 1-2 minutes\n\n b. Speaker 2-4 minutes\n\n c. Speaker 1-2 minutes\n\n d. Direct question- 1 minute, maximum 2 questions\n\n e. Concluding statements- 15 seconds each.",
                    "5. There will be a coin toss to decide the order of questioning and closing arguments. The winner gets to choose whether to take the first or second question or give the first or second closing statement.",
                    "6. The participants will not be allowed to read out from any material but will be allowed to carry plain papers to jot down any points if required.",
                    "7. Exceeding the time limit will result in a penalty of 20%.",
                    "8. The decision of the judge is final.",
                    "9. Judging Criteria:\n\n a. Content of Speech - 40%\n\n b. Expression - 30%\n\n c. Questions posed & Answers - 30%"
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 28,
                "genre": "Literary",
                "name": "Tell a Tale",
                "tagline": "Story Telling",
                "writeup": "Story telling has been a tradition through homes and families, taking this ahead we are\nintroducing a new event, for you to display your talent",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event.",
                    "2.Time limit:6-8 min.",
                    "3.Juding criteria includes \n\na. expressions\n\nb. story & theme\n\nc. audience interaction",
                    "4.Story should be original, and any sense of copy with any instances will lead to direct disqualification of the participant.",
                    "5. Any instance of vulgarity or disturbing content of any order will lead to a forceful halt of performance and immediate disqualification.",
                    "6. Participants can bring their own music for the act in a pen drive but recorded vocals are not allowed."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Film Fest",
        "events": [
            {
                "id": 29,
                "genre": "Film Fest",
                "name": "A Picture Tale",
                "tagline": "Photo Story Competition",
                "writeup": "Capture moments that tell stories beyond words. Enter our photo story\ncompetition, where each image unfolds a narrative that resonates,\nshowcasing your visual storytelling prowess.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual competition where any camera may be used (Digital SLRs, Mobile Camera etc.) by the participant.",
                    "2. Each participant will have to submit a maximum of 10 photographs clicked during the event.",
                    "3. The final submission should be in the form of a story describing the photo story in no more than 100 words.",
                    "4. Only basic photo editing is allowed i.e. cropping, adjusting the hue/saturation, brightness, contrast levels. Photo morphing is strictly not allowed.",
                    "5. The participants will have to submit their photographs online at the mail id provided during the event by the organisers.",
                    "6. Spring Fest will not be responsible for any technical errors due to non compatibility of photos."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 30,
                "genre": "Film Fest",
                "name": "Lights Camera SF",
                "tagline": "Documentary making event",
                "writeup": "This is your chance to make a short film covering one of the biggest social and cultural fests of\nIndia! So, all of spring fest is a game and may the most creative and omniscient team win!",
                "min_participation": 1,
                "max_participation": 4,
                "rules": [
                    "",
                    "1.It is a documentary making event.",
                    "2.Team size should be 4 or less.",
                    "3. The teams will have to come to Kharagpur and shoot a documentary starting\nfrom the 1st day of Spring Fest 2023, encompassing the fest.",
                    "4.The documentary should be under 8 minutes.",
                    "5. The participants will have to submit their videos online at\nfilmfest.sf2023@gmail.com by 30th of January. It can have either an attached file\nor link to the movie. The mail should have the subject “SF Lights Camera SF-\nGroup Name”. Spring Fest is not responsible for any technical problems arising out\nof non-compatibility of the videos.",
                    "6. The complete movie has to be submitted in .mpeg or .avi formats only. Spring\nFest shall not be responsible for any issues arising due to non-compatibility.",
                    "7. Judging criteria includes fest coverage, content, editing and overall impact of\nthe video.",
                    "8. Spring Fest reserves the right to use the video entries on social media (suitable\ncredits will be given to the people responsible for the creation of the content).",
                    "9. The use of copyright content like background music, etc. is not allowed and will\nlead to disqualification"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 31,
                "genre": "Film Fest",
                "name": "SFM",
                "tagline": "Short Film Making Competition",
                "writeup": "Do you consider yourself a visionary or an amateur director? Then Short Film Making competition at Spring Fest is your chance to prove your worth by producing a short film of your own and displaying it on a large screen before a large audience to enjoy and appreciate your brilliance. The short film has to be made on a certain theme that would be released two months before the final event.",
                "min_participation": 10,
                "max_participation": 15,
                "rules": [
                    "",
                    "1. The team size can be 10 to 15 people including actors.",
                    "2. The time range for the short film will be 6 to 8 min.",
                    "3. The movie should either be in Hindi or English or both. No other languages are permitted.",
                    "4. The video may be taken with any device.",
                    "5. A list of specific situations will be released on the website one month before the fest. At least one of these situations has to be included in the short film.",
                    "6. It is imperative that at least one of the actors in the short film registers at the control tent to confirm the authenticity of the short film. The participating actor has to submit his/her SF ID as well, when submitting the video, along with scanned college ID’s, photos and SF ID’s of all the group members.",
                    "7. The participants will have to submit their films online at filmfest.sf2023@gmail.com by 25th of January. It can have either an attached file or link to the movie. The mail should have the subject “SF-Short Film Making-Group Name”",
                    "8. The complete movie has to be submitted in .mpeg or .avi formats only. Spring Fest shall not be responsible for any issues arising due to non-compatibility.",
                    "9. Obscenity of any kind is not allowed and shall lead to disqualification. Photo animations, slide shows, remixes, spoofs and advertisements must be avoided.",
                    "10. The decision of the judges and the organizing team will be final and binding.",
                    "11. Spring Fest reserves the right to use the video entries on social media (suitable credits will be given to the people responsible for the creation of the content).",
                    "12. The use of copyright content like background music, etc. is not allowed and will lead to disqualification."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Quiz",
        "events": [
            {
                "id": 32,
                "genre": "Quiz",
                "name": "BIZTECH",
                "tagline": "   Business and Technology Quiz",
                "writeup": "Time has come to capitalize on the well preserved and reserved acumen for business matters and technical bits. From logos to taglines of the companies to historic people and events in the it industry, nothing is left behind. This is the apt quiz for you if you live on a daily dose of ‘the economic times’ and ‘digit’.\n",
                "min_participation": 1,
                "max_participation": 4,
                "rules": [
                    "",
                    "1.This is a team event. Each team will have a maximum of four members.",
                    "2.The event will take place in two rounds, written prelims followed by finals.",
                    "3.8 teams from the prelims will qualify for the finals.",
                    "4.In the finals, the quiz master will explain the detailed rules before conducting every round.",
                    "5.The quiz master’s decision is final and binding.",
                    "6.The organizing team reserves the right to change or modify any of the rules"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 33,
                "genre": "Quiz",
                "name": "Mary Bucknel Trophy",
                "tagline": "General Quiz",
                "writeup": "The Mary Bucknell trophy is one of the oldest and the most coveted quizzing competition in the east. The general quiz has been a part of spring fest, since the early 70’s. A yearning for knowledge and a passion for quizzing along with general awareness is all that is required.\n",
                "min_participation": 1,
                "max_participation": 4,
                "rules": [
                    "",
                    "1.This is a team event. Each team will have a maximum of four members.",
                    "2.The event will take place in two rounds, written prelims followed by finals.",
                    "3.8 teams from the prelims will qualify for the finals.",
                    "4.In the finals, the quiz master will explain the detailed rules before conducting every round.",
                    "5.The quiz master’s decision is final and binding.",
                    "6.The organizing team reserves the right to change or modify any of the rules"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 34,
                "genre": "Quiz",
                "name": "SpEnt",
                "tagline": "Sports and Entertainment Quiz",
                "writeup": "From page 3 to cricket and kabaddi, this quiz covers it all. The sports and entertainment quiz is tailor made to test your awareness about the glamour world from actors to movies and the sporting world from great sports persons to their statistics and records. Conducted in a unique fashion, there is something in it for every member of the audience.",
                "min_participation": 1,
                "max_participation": 4,
                "rules": [
                    "",
                    "1.This is a team event. Each team will have a maximum of four members.",
                    "2.The event will take place in two rounds, written prelims followed by finals.",
                    "3.8 teams from the prelims will qualify for the finals.",
                    "4.In the finals, the quiz master will explain the detailed rules before conducting every round.",
                    "5.The quiz master’s decision is final and binding.",
                    "6.The organizing team reserves the right to change or modify any of the rules"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Fine Arts",
        "events": [
            {
                "id": 38,
                "genre": "Fine Arts",
                "name": "Finger Painting",
                "tagline": "Finger Painting Competition",
                "writeup": "This is a finger painting competition.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1.This is an individual event.",
                    "2.Participants are needed to make a painting based on a theme given to them \non the spot. The time limit is 2 hours.",
                    "3.Participants are strictly prohibited to use brushes or anything in place of fingers to paint.",
                    "4.All logistics shall be provided on the spot. NO additional logistics apart from \nthose provided in the beginning, can be used.",
                    "5.Use of any outside aids and internet is strictly prohibited and may lead to disqualification."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 39,
                "genre": "Fine Arts",
                "name": "Junk Art",
                "tagline": "Waste Art Competition",
                "writeup": "This is a Waste Art Competition.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "1) Participants have to prepare an art form from all recyclable materials like\nplastic bottles, glass bottle, etc. from a marked area in the campus.\n\n 2)Every participant will be provided 3 hours of time and a kit to pick up the junk\nin the area.\n\n 3) Requirements regarding the paints, papers will be fulfilled by the spring fest\nteam\n\n 4) The participants will submit their entries in the control tent.\n\n 5) Evaluation will be based upon the number of junk materials included, the\ntheme of the art and the creativity in the colour contrast."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 40,
                "genre": "Fine Arts",
                "name": "Paint It",
                "tagline": "Water Color Painting Competition ",
                "writeup": "For all the amateur da Vincis and Picassos out there! Unleash the creativity in you and create new worlds by taking part in this all-watercolor painting competition.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event. Registrations will be accepted on the spot. The time limit is",
                    "2.5 hours.",
                    "2. The event will be conducted in one round.",
                    "3. Participants are needed to make a painting based on a theme given to them on the spot.",
                    "4. Judging shall be based on the depiction of the theme and aesthetic appeal of the painting.",
                    "5. All logistics shall be provided on the spot. NO additional logistics apart from\nthose provided in the beginning can be used.",
                    "6. Use Of any outside aids and the internet is strictly prohibited and may lead to disqualification."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 41,
                "genre": "Fine Arts",
                "name": "Rangoli",
                "tagline": "Rangoli Making Competition",
                "writeup": "This is a Rangoli Making Competition.",
                "min_participation": 1,
                "max_participation": 3,
                "rules": [
                    "",
                    "1. This is a team event. The maximum team size is",
                    "3. On-spot registrations will be accepted.",
                    "2. Participating teams are needed to make a Rangoli based on a theme given on the spot. The time limit for the event is 3 hours.",
                    "3. The size of each Rangoli should be 3ftX 3ft.",
                    "4. There must NOT be any personal indication mark or writing on the job submitted.",
                    "5. All logistics shall be provided on the spot. NO additional logistics apart from\nthose provided in the beginning can be used.",
                    "6. Use of any outside aids and the internet is strictly prohibited and may lead to\ndisqualification."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 35,
                "genre": "Fine Arts",
                "name": "Bran-D",
                "tagline": "Digital Branding Designing Competition",
                "writeup": "Brand-d is the intelligent use of design required to help a brand perform better. If you wish to create a brand name using your skills in design and if you like a tough challenge, then this is a competition for you.\n",
                "min_participation": 2,
                "max_participation": 5,
                "rules": [
                    "",
                    "1.This is a team event. Each team can consist of maximum 5 people.",
                    "2.The event will take place in 2 rounds: Eliminations and Finals.",
                    "3.The problem statement shall be released online through the website and \nour Official Facebook page two months before the fest.",
                    "4.The Eliminations shall be conducted online. Participating teams shall decide the brand name according to the problem statement. The submission portfolio must necessarily consist of the following: \n\n• Corporate Logo • Corporate Tagline/Slogan • Corporate Logistics: Letter Head (21.59 cm x",
                    "27.94 cm), Envelope (22 cm x 11 cm), Business Card (9 cm x 5 cm),  Email Signature, Stationery",
                    "5.The participants are expected to send their brand design portfolio at \nfineart23@gmail.com",
                    "6.The portfolio must be submitted in one of the following formats: jpeg, .tiff, .png.",
                    "7.Shortlisted teams will qualify for finals.",
                    "8.For the final round, the team needs to prepare and present a Marketing Strategy  for promoting and launching the brand for a period of 6 months, defining the  media platforms used and the content and activity on each platform. Presentation  time is 1 5 minutes including setup.",
                    "9.Presentation must include: · Company name: whom you are · A literal descriptor slide: what you do · Tag line and values of the brand: how you do it · Unique company logo: your visual mark · Brand guide: your brand standards and Other designs · Marketing Campaigns: your marketing strategies and corporate branding  strategies",
                    "10.In finals, there should be at least 2 spokespeople and 1 person to handle \nthe technical support"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 36,
                "genre": "Fine Arts",
                "name": "Digital Illustration",
                "tagline": "Digital Art Competition",
                "writeup": "This is a Digital Art Competition.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "1) The participants have to create an art on a digital illustrator depicting the\nmotto of the fest: \" The True Spirit of Youth\".\n\n 2) The use of images available online to integrate directly into the art is strictly\nnot allowed and may lead to disqualification.\n\n 3) The art should be submitted in form of .png or .JPEG format\n\n 4) Participants have to make online submissions on\nfinearts.sf2023@gmail.com."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 37,
                "genre": "Fine Arts",
                "name": "Face Canvas",
                "tagline": "Face Painting Competition",
                "writeup": "Face Canvas is a face painting competition",
                "min_participation": 2,
                "max_participation": 2,
                "rules": [
                    "",
                    "1.This is a team event. Each team Will have 2 members. One member will paint on the other member's face.",
                    "2.Participating teams can depict any theme but the use of the internet and external aid is strictly prohibited.",
                    "3.Time limit for the event is 2 hours.",
                    "4.Stickers and any kind of make-up items are not allowed.",
                    "5.All logistics shall be provided on the spot. NO additional logistics apart from those provided in the beginning, can be used."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 42,
                "genre": "Fine Arts",
                "name": "Sketch It",
                "tagline": "Pencil Sketching Competition",
                "writeup": "Sometimes colours cannot express what may look best in shades of black and white, so “sketch it” with the finest strokes of your pencil.  ",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event. Registrations will be accepted on the spot. The time limit is",
                    "2.5 hours.",
                    "2. The event will be conducted in one round.",
                    "3. Participants are needed to make a painting based on a theme given to them on the spot.",
                    "4. Judging shall be based on the depiction of the theme and aesthetic appeal of the painting.",
                    "5. All logistics shall be provided on the spot. NO additional logistics apart from\nthose provided in the beginning can be used.",
                    "6. Use Of any outside aids and the internet is strictly prohibited and may lead to disqualification."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 43,
                "genre": "Fine Arts",
                "name": "Soapaholic",
                "tagline": "Soap Carving Competition",
                "writeup": "This is a Soap Carving Competition.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event. Registrations Will be accepted on the spot.",
                    "2. Participating teams are needed to carve a soap based on any theme.",
                    "3. Time limit for the event is 2 hours.",
                    "4. The participants would be given only one bar of soap. They have to design on\nthe same bar that is provided.",
                    "5. All logistics shall be provided on the spot. NO additional logistics apart from\nthose provided in the beginning can be used.",
                    "6. Use Of any outside aids and the internet is strictly prohibited and\nmay lead to disqualification."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Humor Fest",
        "events": [
            {
                "id": 44,
                "genre": "Humor Fest",
                "name": "Hilarity Ensues",
                "tagline": "Stand Up Comedy",
                "writeup": "Ready to tickle funny bones and own the spotlight? Step into the laughter zone with our stand-up comedy competition, where your humour takes center stage, bringing a dose of hilarity to the audience.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event and participants will need to perform in Hindi or English or both.",
                    "2. The time limit for the performance is of 5 minutes, exceeding which by 1 minute will lead to disqualification",
                    "3. Any sort of racist, sexist or offensive slurs will lead to immediate disqualification",
                    "4. Judging criteria includes:\n\n A. Content and creativity (40 points)\n\n B. Language and craftsmanship (30 points)\n\n C. Emotion and connection (30 points)\n\n The judges' collective scores across these categories will determine the final outcome of the competition.",
                    "5. Winners get a chance to grab a direct spot in Hilarity ensues during the main fest."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Food Fest",
        "events": [
            {
                "id": 46,
                "genre": "Food Fest",
                "name": "Food Carving",
                "tagline": "Food Competition",
                "writeup": "",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    ""
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 47,
                "genre": "Food Fest",
                "name": "Mixology",
                "tagline": "Cocktail Mixing Competition",
                "writeup": "This is a cocktail mixing competition",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "Rules"
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 45,
                "genre": "Food Fest",
                "name": "Chefs Corner",
                "tagline": " Cooking Competition",
                "writeup": "Are you an artist who loves to create magic in the kitchen? If yes, this is the place for you. Chef’s corner is one of the most novel competitions of spring fest. A unique cooking competition has been a center of attraction for not only master chefs but anyone who loves food.",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1.Click at least 3 photos of the final dish from multiple angles(Front view, top \nview, Sideview, etc.) It should include the name of dish, ingredients, recipe, and \nwhether the recipe is self- made or not.",
                    "2.Click 1 photo of a bite-size portion as well.",
                    "3.Record a video of at least 2 minute showing the most crucial step of your cooking process.",
                    "4.Write the recipe of the dish in a document and save it in .pdf format."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 48,
                "genre": "Food Fest",
                "name": "Top It to Win It",
                "tagline": "The Cupcake Dressing Competition",
                "writeup": "One of the latest additions to the food fest genre is top it to win it, a cupcake dressing workshop cum competition in spring fest. With the basic idea of promoting and popularizing the cupcake culture in India, top it to win it has proved to be an attraction for the youth with chefs and bakeries sharing their skills, knowledge, and experience.\n",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is an individual event.",
                    "2. It will be a single-stage competition.",
                    "3. Participants have to take at least two cupcakes and a list of ingredients required to decorate it is given below. No other ingredients will not be allowed.",
                    "4. Judging will be based on presentation."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Fashion",
        "events": [
            {
                "id": 50,
                "genre": "Fashion",
                "name": "Navyata",
                "tagline": "Fashion Show",
                "writeup": " Fashion is an art, a religion, a peek into a personality. For some, it’s an escape or a disguise while for others it’s just being comfortable. But ultimately, fashion is an individual statement of expression for each of us and Fashionista is the avenue to express yourself. A glimmering show of ethnic wear to mesmerize the desi audience. Spring Fest, as a part of its Diamond Jubilee Edition last year, entered into a nascent stage of a new genre of event.",
                "min_participation": 5,
                "max_participation": 10,
                "rules": [
                    "Elimination",
                    "1. Designers have to mail the following to fashion.sf2023@gmail.com.",
                    "2. A soft copy of their designs for the stage round",
                    "3. Write-ups explaining their designs for the stage round",
                    "4. The designers need to present at least 2 and a maximum of 8 dresses which will be based on one of the below mentioned themes. Each set of design can have multiple numbers of accessories.",
                    "5.Only the designs submitted during this round will be allowed to be worn by the models in the\nstage round. Thus, the submission should necessarily include the designs for the stage round. The\nfirst round of eliminations shall be based on the above-listed submissions.\n\nStage Round",
                    "1.The teams selected from the elimination will perform at Spring Fest.",
                    "2. Team size - 5 -10.",
                    "3. Time Limit: 7 minutes per team (empty stage to empty stage)",
                    "4. There can be multiple teams from a single college",
                    "5. Crossover teams can be formed between students of different colleges,\nall having valid college ID cards for the current year.",
                    "6. Teams can ask for assistance from people outside the college such as\nchoreographers, make-up artists, but these professionals will not be\nconsidered to be a part of the team.",
                    "7. Vulgarity of any kind would lead to disqualification of the team from the\nevent. Hence, if the team feels that any stunt or costume design can be\nconsidered vulgar, it is strongly advised to consult the organizers before\ndisplaying it on stage. The decision of the organizers will be final in case of\nany disputes arising due to this",
                    "8. Teams are suggested to inform the organizers of any kind of props they\nare planning to use. Use of water, fire, pets or any hazardous materials is\nnot allowed. Teams are instructed to consult with the organizers before\nusing any material. Teams are instructed to send the sound track/music to\nbe used during their performance to the organizers through mail at least a\nweek prior to the event.",
                    "9. No branding on clothes will be allowed. Sponsors of colleges will be given\ncompere mentions only.",
                    "10.No animals or birds should be harmed in the making the designs. Doing\nso will lead to immediate disqualification.",
                    "11.JUDGING CRITERIA:\n\n• Designers: originality, depiction of theme, designing, use of props and accessories, use of fabrics\n\n• Models: ramp walk, stage presence, poise, overall appearance\n\n• Team: choreography, music and narration, co-ordination, relevance to the theme, overall performance\n\nThe decision of the judges will be final and binding. The organizing committee reserves the\nright to make any last minute change in the rules."
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 49,
                "genre": "Fashion",
                "name": "Mr and Ms SPRING FEST",
                "tagline": "Personality Hunt Competition",
                "writeup": "Long ago, tribes used daring feats of skill and bravery to choose the worthiest amongst\nthem. To be welcomed to the tribe you, too, must impress the Headman with your... eh,\nskills, for lack of a better word. The stage sets on fire, when the unseen, unforgettable,\nultimate accessory and perception of fashion heralds the arrival of blistering and dynamic\nyouth of this country which finally leads to a classic style of a competition.\n",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. List of participants selected for the final round will be announced through emails.",
                    "2. This will be an Introductory (Time limit – 2 minutes maximum) and Q&A round.",
                    "3. Dress Code: For Boys – Blazer, Tie and dress slacks or a suit with shoes For Girls – Cocktail dress."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 51,
                "genre": "Fashion",
                "name": "Panache",
                "tagline": "Paper Dress Design",
                "writeup": "Spring Fest’s very own fashion show where flamboyance and design sense meet in an impromptu manner. The unique event witnesses dresses made out of paper designed on spot to suit the rigour and grace of the wearer. So run wild your imagination and create a masterpiece worth watching.",
                "min_participation": 2,
                "max_participation": 2,
                "rules": [
                    "",
                    "1.This is a team event. Each team Will have 2 members, one member for whom the outfit Will be designed and the other member who will design the outfit.",
                    "2.Participating teams are needed to make a dress from the logistics provided to them on the spot. Newspapers, A4 sheets, Ribbons, scissor, stapler, pins, and cello tape Will be given to each team. NO additional logistics apart from the ones provided, can be used.",
                    "3.Time limit for the event is 3 hours.",
                    "4.Each team Will have to give a presentation before the judges after the dress designing is over.",
                    "5.Any potentially vulgar designing of the dress will lead to disqualification.",
                    "6.Use Of any outside aids and internet is strictly prohibited and may lead to disqualification"
                ],
                "is_group": true,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            },
            {
                "id": 52,
                "genre": "Fashion",
                "name": "Stash N Show",
                "tagline": "Online Modeling Competition ",
                "writeup": "An online modeling competition to attract the very engaging \nfashion seeking audience on social media. Who doesn’t love to \nboast his/her pictures on Instagram or Facebook and win prizes with\nit? A high-demand event for the youth and followed by many. These \nkind of events are very captivating, very attractive and engross a huge \namount of audience. Fashion and Instagram are going hand-in-hand, aren’t they?",
                "min_participation": 1,
                "max_participation": 1,
                "rules": [
                    "",
                    "1. This is a solo online competition only.",
                    "2. Participants have to upload at least 5 photos of themselves.",
                    "3. Participants should send their portfolio to fashion.sf2023@gmail.com. The subject of the mail should be “Stash N Show – (Your Name) – (SF ID)”. \n(a)Participants need to send an introduction video which will include his/her Name, College Name, SF ID.",
                    "4. The portfolio has to be uploaded on Instagram in a single post from the public account of the participant. Entries from any private account won’t be accepted.",
                    "5. Participants are supposed to tag @iitkgp.springfest in the caption of this portfolio post of Instagram.",
                    "6. Participants are supposed to use the hashtags #sf2k23, #springfest, and #stashnshow in the caption of this portfolio post on Instagram.",
                    "7. Photos should have at least 3 photos among the following 5 shots to form a complete portfolio: Full-length body shot, Editorial fashion shot, Strong Closing Shot, Smiling Shot, Beauty Shot, or Clean Head Shot.",
                    "8. Judging Criteria: Personality, Style, Confidence, Attention details with accessories and costume, Overall Impact."
                ],
                "is_group": false,
                "event_status": true,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    },
    {
        "genre": "Game Fest",
        "events": [
            {
                "id": 53,
                "genre": "Game Fest",
                "name": "Labyrinth",
                "tagline": "Labyrinth",
                "writeup": "Labyrinth",
                "min_participation": 2,
                "max_participation": 5,
                "rules": [
                    "Labyrinth\nRules"
                ],
                "is_group": true,
                "event_status": false,
                "latitude": "22 N",
                "longitude": "66 E",
                "score": 1
            }
        ]
    }
]
