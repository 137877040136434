const details = [
  {
    id: "1",
    mainheading: "INFO",
    content: [

      {
        id: "5",
        subheading: "ABOUT US",
        subcontent:
          "Spring Fest has been an example of achieving huge feats with unparalleled figures ever since its inception in 1960. Spring Fest has grown in stature in terms of its content, participation, and logistics. An exhibition of various cultural performances of the highest calibre and an overwhelming amount of people combined with a hint of fun. With such vastness and diversity, the hospitality of the guests is of paramount importance.",
      },
      {
        id: "6",
        subcontent:
          "We, at SPRING FEST, IIT KHARAGPUR constantly strive towards the satisfaction of everyone. We shall leave no stone unturned in fulfilling the needs of secure accommodation away from home. We strive to make your stay comfortable and your experience a memorable one. Hospitality management would be one of the prime focuses of the TEAM SPRING FEST 2025. You'll get accommodation at Spring Fest, IIT KHARAGPUR for four nights and four days; from 26th January to 28th January at IIT KHARAGPUR, with an exclusive free access to all the events.",
      },

      {
        id: "1",
        subheading: "REGISTRATION CHARGES:",
        subcontent: "Registration Charges at Spring Fest, IIT Kharagpur, is charged @2240 INR only/-  per head for 3 Days and 4 Nights package with 3 pronites passes, complimentary accommodation and SF Kit. (From 25 January 2025 08:00 A.M. to 29 January 2025 10:00 A.M) ",
      },
      {
        id: "2", subheading: "TIMING:",
        subcontent: "Check-In Time - 6:00 A.M. to 10:00 P.M. on Your Check-In Date. \n Check-Out Time - Anytime on or Before Your Check-Out Date. \n The arena and registration desk will remain closed between 7 PM and 10 PM. All the services will resume after 10 PM during fest dates."
      },
      {
        id: "3",
        subheading: "CANCELLATION POLICY:",
        subcontent:
          "Any queries regarding Cancellation or Refund shall be emailed to accomodation@springfest.in . The subject of the email should be 'Cancellation of Accom- modation'. For any cancellations before the deadline, 75% of the total amount shall be refunded within 10 working days after the festival; there shall be no refunds for cancellations after the deadline. Deadline of cancellation: 20th January 2025.",
      },
      {
        id: "17",
        subheading: "What is Contingent?",
        subcontent:
          "A Contingent is a group of participants from a college.",
      },
      {
        id: "18",
        subheading: "What is the benefit of a Contingent?",
        subcontent:
          "1.When you are part of contingent, we will take care that all the members of the Contingent get accommodated in the same common room, separate for boys and girls.\n 2.The Contingent with the highest final score in the end of the Fest will win the Contingent Championship. ",
      },
      {
        id: "19",
        subheading: "What is a Contingent Championship?",
        subcontent:
          " In the Contingent Championship all the contingent will compete and the Contingent with the highest score in the end will win the trophy.",
      },
      {
        id: "20",
        subheading: "How do I register my contingent?",
        subcontent: " To discover this information, kindly navigate to our Accommodation Portal, where you'll find a section dedicated specifically to the contingent. Explore the various options in that section to get detailed insights into accommodation details tailored for contingents. If you have any further queries, the portal should provide comprehensive assistance."
      },
      {
        id: "4",
        subheading: "",
        subcontent:
          "See you here at Spring Fest 2025. \n Regards, \nTeam Spring Fest "
      },
    ],
  },
  {
    id: "2",
    mainheading: "ABOUT US",
    content: [

    ],
  },
  {
    id: "3",
    mainheading: "REACHING IIT KGP",
    content: [
      {
        id: "1",
        subcontent:
          "Kharagpur is about 140 kms west of Kolkata and is well connected to the city by road and rail.\n Kharagpur can be reached in about 2 hours by train or 3 hours by car from Kolkata's Howrah railway station. Kharagpur is also linked by direct train services to the majority of the country's major cities. \nThe Institute is about a 10-minute drive (5 km) from the Kharagpur railway station and a 5 min walk from Hijli station. To get to the Institute, you can hire a private taxi, autorickshaw, or cycle rickshaw. \n **Buses will be provided by SPRING FEST at Kharagpur railway station for the guests to reach IIT Kharagpur campus.",
      },
      {
        id: "2",
        subheading: "You can reach IIT Kharagpur in two ways:",
        subcontent:
          "By Air: The nearest airport to Kharagpur is Kolkata's Netaji Subhas Chandra Bose International Airport (CCU). From Kolkata Airport, take a taxi to Kharagpur from the airport taxi stand. The distance is close to 140 Kilometres. Take a taxi to Howrah railway station. Regular express and local trains run to Kharagpur. The Institute is located 5 Kilometres from Kharagpur Railway Station.\nBy train: Kharagpur is well connected to most major cities of India by rail. The nearest railway station to IIT Kharagpur is Hijli Station. There are frequent trains to Kharagpur. Alternatively, you can reach the Howrah Railway station and take a local or express train to Kharagpur or Book a cab to reach Kharagpur via road. The distance is almost 140KM.",
      },
      {
        id: "3",
        subheading: "Some Important Things To Remember",
        subcontent:
          "1. A Team ID will be allocated to the team on registration which shall be used for future references.\n2. No responsibility will be held by Spring Fest, IIT Kharagpur for any late, lost, or misdirected entries.\n 3. All modes of official communication will be through the Spring fest e-mail. Participants are advised to keep track of all folders in their email accounts.\n 4. Note that at any point of time the latest information will be the one mentioned on the website. However, registered participants will be informed through mail about any changes.\n 5. The decision of the organizers or judges shall be treated as final and binding on all.",
      },
    ],
  },
  {
    id: "4",
    mainheading: "FAQS",
    content: [
      {
        id: "1",
        subheading: "What will be included in this price?",
        subcontent:
          "The provided price includes all pronites passes. Additionally, it covers the registration fee, ensuring participation in the event. Moreover, complimentary accommodation is part of the package, enhancing the overall experience. ",
      },
      {
        id: "2",
        subheading: "What is the payment procedure?",
        subcontent:
          "To access the registration and accommodation facilities, please navigate to www.springfest.in and log in or register if you haven't already.Once logged in, locate the dashboard and click on the Accommodation button. This will direct you to the accommodation section. In this section, you will find the payment option to complete the transaction for your chosen accommodation. Ensure you follow the prompts and provide any necessary details for a seamless payment process. Should you encounter any difficulties, refer to the website's support or contact information for assistance. Make sure to complete the registration and payment steps to secure your accommodation for a hassle-free experience at the event.",
      },
      {
        id: "17",
        subheading: "How do I know I have paid for accommodation?",
        subcontent:
          "Upon completing the registration and payment process through our portal, you will receive a confirmation email containing your transaction ID. Additionally, your paid status will be promptly updated on the dashboard, ensuring real-time visibility of your transaction. This seamless confirmation system enhances the overall transparency of your registration and payment experience. Keep an eye on your email inbox for the transaction details, and conveniently track your payment status on the user-friendly dashboard. We prioritize providing swift and accurate confirmations to streamline your event participation process.",
      },
      {
        id: "18",
        subheading: "What payment procedure is accepted?",
        subcontent:
          "We offer flexible payment options to enhance your convenience. Choose from a variety of methods, including UPI, net banking, and debit/credit card payments. This ensures a seamless and secure transaction experience for our valued customers. Your preferred payment mode is just a click away, making the process quick and hassle-free. Join us in simplifying your payment journey with these diverse and accessible options.",
      },
      {
        id: "3",
        subheading: "What are the documents that guests have to carry?",
        subcontent:
          "1. Any valid Govt photo ID.\n2. Print out/screenshot of Email confirmation.\n3. Valid College ID for participants.",
      },
      // {
      //   id: "4",
      //   subheading: "What are the accommodation charges?",
      //   subcontent:
      //     "Accommodation at Spring Fest, IIT Kharagpur, is charged @ 2240 INR only/- per head for 4 Days and four nights package. (From 25 January 2025 08:00 A.M. to 29 January 2025 10:00 A.M)",
      // },
      {
        id: "5",
        subheading: "What documents are required for availing accommodation here at campus?",
        subcontent:
          "1. Any valid Govt photo ID. \n 2. Print out/screenshot of Email confirmation. \n 3. Valid College ID for participants.",
      },
      {
        id: "6",
        subheading:
          "Will the Acco tent be open 24x7?",
        subcontent:
          "Operating around the clock, our services will be available 24x7, offering continuous support. However, please note that there will be a temporary interruption from 7 pm to 10 pm between January 26th and January 28th. We appreciate your understanding during this brief downtime.",
      },
      {
        id: "7",
        subheading: "What kind of accommodation is provided?",
        subcontent:
          "Accommodation is provided on a shared basis inside campus halls. One mattress, and enough space for keeping luggage, and other essential commodities will be provided. Girls and boys will be accommodated separately. The number of guests in a room will be decided by Spring Fest and will be allotted by the Spring fest team.",
      },
      {
        id: "8",
        subheading: "Do the accommodation charges include food facilities too?",
        subcontent:
          "Breakfast,Lunch and dinner is not included in the accomodation fee but can be purchased from the food court in the arena, night canteens and other stalls present on the campus.",
      },
      {
        id: "9",
        subheading:
          "Do we get any food facilities at outside accommodation places?",
        subcontent:
          "You can purchase your meals from in-campus restaurants or nearby hotels.",
      },
      {
        id: "10",
        subheading: "What are the food facilities inside the campus?",
        subcontent:
          "The arena will feature a food outlet, providing convenient dining options. Additionally, the SF Cafe will be available for those seeking a cozy spot for refreshments. For even more choices, the night canteen will offer a variety of food options, ensuring everyone finds something to suit their taste. Enjoy the event with a diverse range of culinary delights right at your fingertips.",
      },
      {
        id: "11",
        subheading: "What about the hospital facility?",
        subcontent:
          "In case anyone falls ill, they are advised to report at the accomodation desk so that appropriate arrangements can be done for them. A first aid kit shall also be present at the accomodation desk which can be availed by the guests.",
      },
      {
        id: "12",
        subheading:
          "What about the security facilities during the fest in the campus?",
        subcontent:
          "IIT Kharagpur campus has a vigilant and round-the-clock security service. To ensure the safety of the participants, there will be additional security guards in hostels in order to avoid thefts and other mishaps. There will be a Strong room for highly valuable products. Although Spring Fest team will not take responsibility for any theft or other mishaps happened at the accommodation. So guests are requested to take care of their luggage and valuable items.",
      },
      {
        id: "13",
        subheading: "Where will I get my accommodation?",
        subcontent:
          "You will be getting accommodation inside the campus in various halls depending upon availability.",
      },
      {
        id: "14",
        subheading: "Can I enter the IIT-KGP campus anytime?",
        subcontent:
          "You can enter IIT Main gate anytime by showing valid photo ID proof from 6 am to 10 pm, however, you need to have accommodation inside the campus to stay in campus after 10pm.",
      },
      {
        id: "15",
        subheading:
          "We are a group of people not participating in any of the competitions or workshops and just coming to Spring Fest for enjoyment. Would we be provided accommodation?",
        subcontent: "Yes, You just have to pay the Accommodation fees.",
      },
      {
        id: "16",
        subheading: "Where do I have to report during the fest?",
        subcontent:
          "You need to come to the Accommodation tent at the Spring Fest arena near the Computer science building.",
      },

    ],
  },
  {
    id: "5",
    mainheading: "RULES",
    content: [
      {
        id: "1",
        subcontent:
          "We care about our campus as much as you care about enjoying to the fullest in Spring Fest. Let’s follow these simple rules to make this SF a memorable one and not a dreadful one just because of something you did wrong.",
      },
      {
        id: "2",
        subcontent:
          "At a time, the payment of maximum 1 participants can be done with the same transaction ID. The participant/team is requested to note down their transaction ID & accommodation ID and keep it with them for their convenience.",
      },
      {
        id: "3",
        subcontent:
          "Participants from a college may have a maximum of 2 professors/faculty with their institute ID card. They will be charged and provided accommodation only on showing the ID card. The organising team will try their best to provide separate rooms to the faculty but it is not promised.",
      },
      {
        id: "4",
        subcontent:
          "The accommodation charges and other necessary details regarding the payment will be available under the tab Accommodation Charges.",
      },
      {
        id: "5",
        subcontent:
          "Participants from a college may have a maximum of 2 professors/faculty with their institute ID card. They will be charged and provided accommodation only on showing the ID card. The organising team will try their best to provide separate rooms to the faculty but it is not promised.",
      },
      {
        id: "6",
        subcontent:
          "Accommodation tent will be closed during the time of Starnights i.e. from 6pm to 11pm on all days from 24th-26th January 2025. No calls/requests to provide accommodation can be entertained at this time. However, if during this time, you enter the campus, you may wait in the Old Badminton Court at Gymkhana.",
      },
      {
        id: "7",
        subcontent:
          "Accommodation will be provided to the participants from the check-in time to the check-out time as mentioned on the Accommodation Portal/Participant Slip. If the participant/team stays for more than the check-out time and refuses to vacate his allotted space, a fine of Rs. 500 per person will be levied on the participant/team. ",
      },
      {
        id: "8",
        subcontent:
          "If you vacate the space allotted 12 hours before the check-out time entered and ask for the refund of blankets, you will not be given the refund of blankets and this will lead to a fine of Rs. 300 per participant.",
      },
      {
        id: "9",
        subcontent:
          "The participants will be reminded via SMS one-hour before the check-out time to vacate the allotted space.",
      },
      {
        id: "10",
        subcontent:
          "Students are not allowed to eat in the hall mess. However, they may eat in hall canteens, food stalls and eateries.",
      },
      {
        id: "11",
        subcontent:
          "Participants may have to share the mattresses as allotted.",
      },
      {
        id: "12",
        subcontent:
          "Blankets should not be exchanged.",
      },

      {
        id: "13",
        subcontent:
          "Blankets and mattresses should not be taken outside the hall. If found misplaced, the participant shall be fined Rs. 500 per mattress.",
      },
      {
        id: "14",
        subcontent:
          "Blankets can be availed from the Accommodation tent at the security deposit of Rs. 100 per blanket.",
      },
      {
        id: "15",
        subcontent:
          "Blankets should be submitted at the Accommodation tent while leaving the campus else the security deposit will not be returned. Refundable amount would be refunded only when the Accommodation slip is produced at the Finance tent along with the blankets on 26th and 29th January from 4pm-6:30pm & 11pm onwards.",
      },

      {
        id: "16",
        subcontent:
          "Participants found littering anywhere within the Hall Premises shall be penalised as accordingly by Hall Authorities.",
      },
      {
        id: "17",
        subcontent:
          "Smoking, alcoholism or any other kind of substance abuse is not allowed inside hostel premises. The organising committee reserves every right to fine/deport the participant/team if found violating the above rules. This shall be done at full discretion of the Organising Committee. Also, no refund will be given in case of deporting.",
      },
      {
        id: "18",
        subcontent:
          "Frisking of your belongings for security reasons may be done at any time in case of suspicion of possession of alcohol/drugs etc. However, this will be done by security officials in your presence and you are requested to cooperate with them.",
      },
      {
        id: "19",
        subcontent:
          "Any kind of molestation/theft/harm etc. will be considered as severe criminal offence and the case shall be taken straight away to the police station. Same will be reported to your college administration and may result in suspension from your own college or criminal charges.",
      },
      {
        id: "20",
        subcontent:
          "Any kind of molestation/theft/harm etc. will be considered as severe criminal offence and the case shall be taken straight away to the police station. Same will be reported to your college administration and may result in suspension from your own college or criminal charges.",
      },
      {
        id: "21",
        subcontent:
          "In case of any complaint made against the behaviour of the participant which causes any discomfort to the hostel boarders or other participants, the Organizing Committee’s decision would be final and binding.",
      },
      {
        id: "22",
        subcontent:
          "It shall be the responsibility of the participants to maintain hostel property. In case of any damage to the property of the hostel, the participants shall be fined/deported according to the extent of the damage by decision of the organising committee.",
      },
      {
        id: "23",
        subcontent:
          "Please carry your institute ID card/institute medical book/library card with you at all times to avoid discomfort.",
      },
      {
        id: "24",
        subcontent:
          "All rooms have to be vacated by 30th January 2025 strictly by 2pm.",
      },

    ],
  },
];

export default details;