const allEvents=[
    {
        id: 1,
        event: "Centrifuge"
    },
    {
        id: 2,
        event: "Nrityakala"
    },
    {
        id: 3,
        event: "Shake A Leg"
    },
    {
        id: 4,
        event: "Shuffle"
    },
    {
        id: 5,
        event: "Two For A Tango"
    },
    {
        id: 6,
        event: "Beat IT"
    },
    {
        id: 7,
        event: "Lakeside Dreams"
    },
    {
        id: 8,
        event: "Notes Less Travelled"
    },
    {
        id: 9,
        event: "Rapmania"
    },
    {
        id: 10,
        event: "Retrowave"
    },
    {
        id: 11,
        event: "Sargam"
    },
    {
        id: 12,
        event: "SF Idol"
    },
    {
        id: 13,
        event: "Wildfire"
    },
    {
        id: 14,
        event: "I Me Myself"
    },
    {
        id: 15,
        event: "Mime"
    },
    {
        id: 16,
        event: "Nukkad"
    },
    {
        id: 17,
        event: "Rangmanch"
    },
    {
        id: 18,
        event: "A Mighty Pen"
    },
    {
        id: 19,
        event: "Dumb C"
    },
    {
        id: 20,
        event: "English Poetry Slam"
    },
    {
        id: 21,
        event: "Hindi Poetry Slam"
    },
    {
        id: 22,
        event: "Impromptu"
    },
    {
        id: 23,
        event: "India Calling"
    },
    {
        id: 24,
        event: "Jumble The Good Word"
    },
    {
        id: 25,
        event: "Just A Minute"
    },
    {
        id: 26,
        event: "National Level Debate"
    },
    {
        id: 27,
        event: "Poetry Slam"
    },
    {
        id: 28,
        event: "Tell a Tale"
    },
    {
        id: 29,
        event: "A Picture Tale"
    },
    {
        id: 30,
        event: "Lights Camera SF"
    },
    {
        id: 31,
        event: "SFM"
    },
    {
        id: 32,
        event: "BIZTECH"
    },
    {
        id: 33,
        event: "Mary Bucknel Trophy"
    },
    {
        id: 34,
        event: "SpEnt"
    },
    {
        id: 38,
        event: "Finger Painting"
    },
    {
        id: 39,
        event: "Junk Art"
    },
    {
        id: 40,
        event: "Paint It"
    },
    {
        id: 41,
        event: "Rangoli"
    },
    {
        id: 35,
        event: "Bran-D"
    },
    {
        id: 36,
        event: "Digital Illustration"
    },
    {
        id: 37,
        event: "Face Canvas"
    },
    {
        id: 42,
        event: "Sketch It"
    },
    {
        id: 43,
        event: "Soapaholic"
    },
    {
        id: 44,
        event: "Hilarity Ensues"
    },
    {
        id: 45,
        event: "Chefs Corner"
    },
    {
        id: 46,
        event: "Food Carving"
    },
    {
        id: 47,
        event: "Mixology"
    },
    {
        id: 48,
        event: "Top It to Win It"
    },
    {
        id: 49,
        event: "Mr and Ms SPRING FEST"
    },
    {
        id: 50,
        event: "Navyata"
    },
    {
        id: 51,
        event: "Panache"
    },
    {
        id: 52,
        event: "Stash N Show"
    },
    {
        id: 53,
        event: "Labyrinth"
    }
]

export default allEvents;