import { useContext } from "react";
import { UserContext } from "../../context/userContext";

export default function DProfile() {

    const { user } = useContext(UserContext);

    return (
        <>
            <div className="dashboard-modal-box__content-heading">
                <h3>PROFILE</h3>
            </div>
            <div className="dashboard-modal-box__content__items" id="dashboard-profile-modal">
                <div className="dashboard-modal-box__content__items-list-item">
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        NAME
                    </div>
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        {user.user.name}
                    </div>
                </div>
                <div className="dashboard-modal-box__content__items-list-item">
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        EMAIL
                    </div>
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        {user.user.email}
                    </div>
                </div>
                <div className="dashboard-modal-box__content__items-list-item">
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        MOBILE
                    </div>
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        {user.user.mobile}
                    </div>
                </div>
                <div className="dashboard-modal-box__content__items-list-item">
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        SF-ID
                    </div>
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        {user.user.sfId}
                    </div>
                </div>
                <div className="dashboard-modal-box__content__items-list-item">
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        CITY
                    </div>
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        {user.user.city}
                    </div>
                </div>
                <div className="dashboard-modal-box__content__items-list-item">
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        COLLEGE
                    </div>
                    <div className="dashboard-modal-box__content__items-list-item__name">
                        {user.user.college}
                    </div>
                </div>
            </div>
        </>
    )
}