import axios from "axios"
import { useState, useEffect, useContext } from "react"
import { UserContext } from "../../context/userContext"

import createToast from "../../utils/createToast"

export default function Event() {

    const [soloEvents, setSoloEvents] = useState([])
    const [groupEvents, setGroupEvents] = useState([])
    const { isLoggedIn, user } = useContext(UserContext)

    // console.log(groupEvents)

    const getEvents = async () => {
        try {
            // let url = "https://masterapi.springfest.in/api/user/registered_events";

            // const response = await axios.post(url, {
            //     "token": user.token
            // })

            // if (response.data.code === 0) {
                setSoloEvents(user.soloEventData)
                setGroupEvents(user.groupEventData)
            // } else {
                // console.error(response.data.message)
            // }
        } catch (error) {
           console.log("could not fetch Events");
        }
    }

    const handleDeRegisterMember = async (event_id) => {
        try {
            // This is the sole deregistration codebase
            const url = "https://masterapi.springfest.in/api/event/deregister/member";
            const response = await axios.post(
                url,
                {
                    "token": user.token,
                    "eventId": event_id,
                    "membersToDeregister": [{
                        "sfId": user.sfId,
                        "email": user.email
                    }]
                    
                }
            )

            if (response.data.code === 0) {
                createToast("Successfully Deregistered", "success");
                getEvents();
            }
        } catch (error) {
            console.log("Error in deregistering Check your Network");
        }

    }

    const handleDeRegisterTeamMember = async (event_id) => {
        try{
            //This is group member deregistration codebase
            const url = "https://masterapi.springfest.in/api/event/deregister/member";
            axios.post(
                url,
                {
                    "token": user.token,
                    "eventId": event_id,
                    "membersToDeregister": [{
                        "sfId": user.sfId,
                        "email": user.email
                    }]
                }
            )
                .then((response) => {
                    if (response.data.code === 0) {
                        createToast("Successfully Deregistered", "success");
                        getEvents();
                    }
                    else {
                        createToast(response.data.message , "error");
                    }
                })
        }
        catch (error) {
            console.log("Error in deregistering Check your Network");
        }
    }

    const handleDeRegisterTeam = async (event_id, group_id) => {
        try {
            //This is group deregistration codebase 
            const url = "https://masterapi.springfest.in/api/event/deregister/team";
            axios.post(
                url,
                {
                    "token": user.token,
                    "eventId": event_id,
                }
            )
                .then((response) => {

                    if (response.data.code === 0) {
                        // console.log("Deregistering team")
                        createToast("Successfully Deregistered", "success");
                        getEvents();
                    }
                    else {
                        createToast(response.data.message , "error");
                    }
                })
        } catch (error) {
            console.log("Error in deregistering Check your Network");
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            getEvents()
        }
        else {
            console.log("Not logged in")
        }
    }, [isLoggedIn])

    // console.log(soloEvents[0].eve, groupEvents)


    return (
        <>
            <div className="dashboard-modal-box__content-heading">
                REGISTERED EVENTS
            </div>
            <div className="dashboard-modal-box__content__items">
                <div className="dashboard-modal-box__content__items-heading">
                    SOLO EVENTS
                </div>
                <div className="dashboard-modal-box__content__items-list">
                    {
                        soloEvents.length ?
                        soloEvents.map((item, index) => {
                            return (
                                <div  key={index} className="dashboard-modal-box__content__items-list-item">
                                    <div className="dashboard-modal-box__content__items-list-item__name">
                                        {item.event.name}
                                    </div>
                                    <div className="dashboard-event-deregister dashboard-modal-box__content__items-list-item__name" onClick={() => handleDeRegisterMember(item.event_id)}>
                                        Deregister
                                    </div>
                                </div>
                            )
                        }) : (
                            <div className="dashboard-modal-box__content__items-list-item">
                                <div className="dashboard-modal-box__content__items-list-item__name">
                                    No Events Registered
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="dashboard-modal-box__content__items">
                <div className="dashboard-modal-box__content__items-heading">
                    GROUP EVENTS
                </div>
                <div className="dashboard-modal-box__content__items-list">
                    {
                        groupEvents.length ?
                        groupEvents.map((item, index) => {
                            return (
                                <>
                                    <div  className="dashboard-modal-box__content__items-list-item" key={index}>
                                        <div className="dashboard-modal-box__content__items-list-item__name">
                                            <h3>{item.event.name}</h3>
                                        </div>
                                        <div className="dashboard-event-deregister dashboard-modal-box__content__items-list-item__name" onClick={() => handleDeRegisterTeam(item.event_id, item.group_id)} >
                                            Deregister Team
                                        </div>
                                    </div>
                                    <div  className="dashboard-events-members">
                                        {
                                            item.GroupMembers &&
                                            item.GroupMembers.map((member, index) => {
                                                return (
                                                    <div className="member-deregistration">
                                                    <div className="dashboard-events-members__item" key={index}>
                                                        {member.user.sfId}-{member.user.name}
                                                    </div>
                                                    <button className=" dereg-button-new dashboard-event-deregister dashboard-modal-box__content__items-list-item__name " onClick={() => handleDeRegisterTeamMember(item.event_id)} >
                                            Deregister member
                                        </button>

                                        </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            )
                        }) : (
                            <div className="dashboard-modal-box__content__items-list-item">
                                <div className="dashboard-modal-box__content__items-list-item__name">
                                    No Group Events Registered
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}